import { createSignal, onMount } from "solid-js";
import { DateTime } from "luxon";

import styles from "./Clock.module.css";

export function createClock() {
  const [time, setTime] = createSignal(new Date());

  onMount(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(interval);
  });

  return time;
}

export default function Clock() {
  const time = createClock();
  return (
    <div class={styles.clock}>
      <span>
        {DateTime.fromJSDate(time()).toFormat("EEEE dd MMMM, HH.mm", {
          locale: "it",
        })}
      </span>
    </div>
  );
}
